'use client';

import { FlexBoxCentered } from 'libs/components/Flex/flex-box';
import Banner1 from 'libs/components/home-sections/banner1';
import Banner2 from 'libs/components/home-sections/banner2';
import Banner3 from 'libs/components/home-sections/banner3';
import Banner4 from 'libs/components/home-sections/banner4';
import Banner5 from 'libs/components/home-sections/banner5';
import BlogSection from 'libs/components/home-sections/blog-section';
import TrustedPartners from 'libs/components/home-sections/trusted-partners';
import HorizontalCard from 'libs/components/horizontal-card';
import SharkTankBanner from 'libs/components/shark-tank-banner';

export default function Index({ cardData }: { cardData: any }) {
  return (
    <>
      <Banner1 />
      {/* <h1
        style={{
          position: 'absolute',
          width: 10,
          height: 10,
          padding: 0,
          overflow: 'hidden',
          clip: 'rect(0, 0, 0, 0)',
          border: 0,
        }}
      >
        Spark Endless Creation with Premium Design Assets and Templates
      </h1>
      <Image
        src={`${process.env.CONTENT_BASE_URL}/offer-banner.webp`}
        alt="DesignTemplate Independance Sale"
        loading="eager"
        layout='responsive'
        width={3132}
        height={1001}
        onClick={() => { push("/pricing"); }}
        priority={true}
        style={{ cursor: 'pointer' }}
      /> */}

      <Banner2
        isTemplate={true}
        title="new release"
        subtitle="Experience daily creativity with designTemplate.io continuous stream of new design assets"
        cardData={cardData && cardData[0]?.items}
      />
      <Banner2
        template="free"
        isTemplate={true}
        title="Explore Our Free Templates"
        subtitle="Access our wide range of free, high-quality templates to fuel your creativity and projects, exclusively at designTemplate.io."
        cardData={cardData && cardData[1]?.items}
      />

      <Banner2
        template="free"
        isTemplate={false}
        title="Wedding Collection"
        subtitle="Craft unforgettable wedding invitations with our designer-exclusive assets."
        cardData={cardData && (cardData[2]?.tagItems || cardData[2]?.items)}
      />
      <Banner3>
        <Banner4 />
      </Banner3>
      <Banner3>
        <SharkTankBanner />
      </Banner3>

      <HorizontalCard />
      <BlogSection blogData={cardData && cardData?.[3]} />
      <Banner5 />
      <FlexBoxCentered>
        <TrustedPartners />
      </FlexBoxCentered>
    </>
  );
}
