import { Container, Stack, Typography } from '@mui/material';

import Card2 from 'libs/components/cards/card2';
import { FlexRowCenter } from 'libs/components/flex-box';
import { typography } from 'theme/typography';

const unleashPower = [
  {
    src: `${process.env.CONTENT_BASE_URL}/unleashPower1.webp`,
    title: 'exclusive Premium designs',
    description:
			'Discover premium design assets crafted by top production studios. From dynamic motion graphics to high-end CGI and VFX.',
  },
  {
    src: `${process.env.CONTENT_BASE_URL}/unleashPower2.webp`,
    title: 'Universal Design Assets',
    description:
			'Explore our comprehensive range of design assets tailored to meet every need of industry, ready for immediate use straight out of the box.',
  },
  {
    src: `${process.env.CONTENT_BASE_URL}/unleashPower3.webp`,
    title: 'Flexible pricing Options',
    description:
			'Tailored Flexibility From Solo to Corporate, Choose Your Perfect Plan Subscription or Single Purchase.',
  },
  {
    src: `${process.env.CONTENT_BASE_URL}/unleashPower4.webp`,
    title: 'Licensing Peace:worry-free',
    description:
			'Unlock Exclusive Content with Peace of Mind: Simple Licensing for Hassle-Free Usage.',
  },

];

const Banner4 = () => {
  return (
    <>
      <Stack
        alignItems={'center'}
        spacing={7}
        justifyContent={'center'}
        width={'100%'}
      >
        <Container maxWidth="lg" sx={{ height: '100%' }}>
          <Stack spacing={1} paddingTop={'1.5rem'} justifyContent={{ md: 'stretch' }} alignItems={'center'}>
            <Typography
              fontFamily={typography.fontFamily}
              fontSize={{ md: '2rem', xs: '1.4rem' }}
              textTransform={'capitalize'}
              lineHeight={1}
              fontWeight={600}
              component="h2"
              color="black"
              textAlign={{ md: 'center' }}
            >
							Solve design challenges effortlessly
            </Typography>
            <Typography
              fontFamily={typography.fontFamily}
              fontSize={{ md: '1rem', xs: '0.8rem' }}
              textTransform={'capitalize'}
              color="#5E5E5E"
              lineHeight={'normal'}
              textAlign={{ md: 'center' }}
            >
							Streamline your design challenges with our innovative solutions.
            </Typography>
          </Stack>
          <FlexRowCenter
            flexDirection={{
              xs: 'column',
              md: 'row',
            }}
            height={{ lg: '19rem', md: '24rem', xs: 'auto' }}
          >
            {unleashPower?.map((item) => (
              <Card2
                key={item.src}
                title={item.title}
                descrition={item.description}
                imageSrc={item.src}
                alignment='justify'
              />
            ))}
          </FlexRowCenter>
        </Container>
      </Stack>
    </>
  );
};

export default Banner4;
