import { useSwiper } from 'swiper/react';

import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';

import { themeColors } from 'theme/theme-colors';

const PrevNavigationButton = ({count}:{count:boolean}) => {
  const swiper = useSwiper();

  return (
    <>
      <ArrowCircleLeftOutlinedIcon
        sx={{
          color: '#6E5BE4',
          position: 'absolute',
          cursor: 'pointer',
          zIndex: 1000,
          borderColor:'blue',
          background:'#fff',
          borderRadius: '50px',
          left: 0,
          display: !count ? 'none' : 'block',
          top: '40%',
          '&:hover': {
            color: themeColors?.primary[500],
          },
        }}
        fontSize="large"
        onClick={() => swiper.slidePrev()}
      />
    </>
  );
};

export default PrevNavigationButton;
