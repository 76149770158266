import { Box, styled } from '@mui/material';

export const StyledBox = styled(Box)(({ theme }) => ({
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  textOverflow: 'ellipsis',
  textTransform: 'capitalize',
  WebkitLineClamp: 2,
  '&  *':{
    fontSize:'0.3rem',
    display:'none'
  },
  '& > p':{
    display:'block !important',
    fontSize:'0.9rem !important',

  },
  '& > h1,& > h2,& > h3,& > h4,& > h5,& > h6':{
    display:'block !important',
    fontSize:'0.9rem !important',
    fontWeight:'600 !important',
    [theme.breakpoints.down('xs')]: {
      fontSize:'1rem !important'
    }
  },
  maxHeight:'20rem',
  maxWidth:'20rem'
}));
