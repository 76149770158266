import Image from 'next/image';
import Link from 'next/link';

import { Stack, Typography } from '@mui/material';

import { Blog } from 'libs/dt-types/response-types/blog';
import { StyledBox } from './style';

const BlogCard = ({ blog }: { blog: Blog }) => {
  return (
    <Stack
      width={'100%'}
      sx={{
        boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.15)',
        borderRadius: '0.8rem',
        border:'0.5px solid #d5d5d582',
        cursor: 'pointer',
        minHeight: '10rem',
        minWidth: '100%',
      }}
    >
      <Link
        prefetch={false}
        href={`${process.env.BLOG_URL}/${blog?.category?.slug}/${blog?.slug}`}
      >
        <Image
          src={`${process.env.NEXT_PUBLIC_CLOUD_SERVICE_URL}/blogs/blog/${blog?.image}`}
          loading="lazy"
          alt="new blog image"
          width={0}
          height={0}
          style={{
            width: '100%',
            height: 'auto',
            borderTopLeftRadius: '0.8rem',
            borderTopRightRadius: '0.8rem',
            objectFit: 'cover',
            aspectRatio: '16 / 9',
          }}
        />
        <Stack gap={1} padding={'0.8rem'}>
          <Typography fontWeight={'600'}>{blog?.title}</Typography>
          <StyledBox color={'#7B7B7B'}>{blog?.sub_heading}</StyledBox>
        </Stack>
      </Link>
    </Stack>
  );
};

export default BlogCard;
