'use client';
import Image from 'next/image';

import MenuIcon from '@mui/icons-material/Menu';
import Search from '@mui/icons-material/Search';
import { AppBar, Button, Stack, styled } from '@mui/material';

import { NavLink } from 'libs/components/nav-link';
import FlexBox from '../flex-box/flex-box';

export const Title = styled('h1')(({ theme }) => ({
  color: '#303538',
  fontSize: '1.3rem',
  fontWeight: '600',
  fontStyle: 'normal',
  lineHeight: 'normal',
  [theme.breakpoints.down(1300)]: {
    fontSize: '1rem',
  },
}));

export const SpanTitle = styled('span')(() => ({
  color: 'black',
  fontWeight: '400',
}));

export const Logo = styled(Image)(({ theme }) => ({
  width: 'auto',
  height: '4rem',
  marginTop: '0.1rem',
  [theme.breakpoints.down(1300)]: {
    width: 'auto',
    height: '4rem',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const LogoMobile = styled(Image)(({ theme }) => ({
  width: 'auto',
  height: '3rem',
  marginTop: '0.1rem',
  [theme.breakpoints.down(1300)]: {
    width: 'auto',
    height: '2.5rem',
  },
}));

export const NavBar = styled(FlexBox)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: 'auto',
  gap: '1.2rem',
  [theme.breakpoints.down(650)]: {
    display: 'none',
  },
}));

export const Navlink = styled('a')(() => ({
  color: '#7B7B7B',
  fontSize: '0.9rem',
  fontWeight: '500',
  lineHeight: 'normal',
  fontStyle: 'normal',
  '&:last-of-type': {
    background: 'linear-gradient(90deg, #6F64FF 0%, #FF4E6E 100%)',
    backgroundClip: 'text',
    color: 'transparent',
  },
}));

export const SearchBar = styled('form')(({ theme }) => ({
  width: 'auto',
  maxWidth: '22rem',
  minWidth: '18rem',
  height: '2.25rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '0.5px solid #D1D1D1',
  borderRadius: '1.5rem',
  [theme.breakpoints.down(862)]: {
    minWidth: '12rem',
  },
}));

export const SearchBarHome = styled('form')(({ theme }) => ({
  width: 'auto',
  maxWidth: '15rem',
  height: '2.8rem',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  border: '0.5px solid #D1D1D1',
  borderRadius: '0.3125rem',
  [theme.breakpoints.down('md')]: {
    height: '2rem',
    paddingRight: '0.15rem',
  },
}));

export const SearchIcon = styled(Search)(({ theme }) => ({
  color: 'white',
  width: '1.2rem',
  height: '1.2rem',
  [theme.breakpoints.down('md')]: {
    width: '0.8rem',
    height: '0.8rem',
  },
}));

export const InputField = styled('input')(() => ({
  width: '10rem',
  height: '1.5rem',
  border: 'none',
  textDecoration: 'none',
  outline: 'none',
  background: 'transparent',
}));

export const OptionCategory = styled('select')(() => ({
  width: '7rem',
  height: '2rem',
  border: 'none',

  '&:focus': {
    outline: 'none',
    border: 'none',
  },
}));

export const Option = styled('option')(() => ({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: '500',
}));

export const SignupButton = styled(Button)(({ theme }) => ({
  border: 'none',
  color: 'white',
  fontSize: '1rem',
  fontWeight: '500',
  borderRadius: '3rem',
  backgroundColor: '#6E5BE4',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: '#5D4CC6',
  },
  '&:active': {
    transform: 'scale(0.97)',
    transition: 'transform 0.1s ease',
  },
  [theme.breakpoints.up('md')]: {
    width: '6rem',
    height: '2.25rem',
    fontSize: '1rem',
  },
  [theme.breakpoints.down('md')]: {
    width: '4rem',
    fontSize: '0.8rem',
    height: '1.8rem',
  },
}));

export const LicenseButton = styled('button')(({ theme }) => ({
  border: 'none',
  color: 'white',
  fontSize: '0.8rem',
  fontWeight: '500',
  borderRadius: '3rem',
  backgroundColor: '#6E5BE4',
  height: '2.25rem',
  cursor: 'pointer',
  ':hover:not(:disabled)': {
    backgroundColor: '#5D4CC6',
  },
  '&:active:not(:disabled)': {
    transform: 'scale(0.97)',
    transition: 'transform 0.1s ease',
  },
  [theme.breakpoints.up('md')]: {
    width: '7rem',
  },
  [theme.breakpoints.down('md')]: {
    width: '7rem',
    fontSize: '0.8rem',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const MenuBurger = styled(MenuIcon)(() => ({
  width: '2rem',
  height: '2rem',
  color: '#6E5BE4',
}));

export const HeaderAppBar = styled(AppBar)({
  background: '#FFFDFC',
  boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.10)',
  width: '100%',
  position: 'sticky',
  top: 0,
  padding: '1%',
});

export const SearchButton = styled(Button)(() => ({
  width: '2.2rem',
  height: '2rem',
  gap: '0.2rem',
  borderRadius: '50%',
  color: 'black',
  background: 'inherit',
  border: '1px solid #d5d5d5',
}));

export const Navbar = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: { display: 'flex', flexDirection: 'row' },
  gap: '1rem',
}));

export const StyledImage = styled(Image)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const StyledNavLink = styled(NavLink)(({ theme }) => ({
  [theme.breakpoints.down(1000)]: {
    display: 'none',
  },
}));

export const StyledNavLink2 = styled('a')(({ theme }) => ({
  position: 'relative',
  transition: 'color 150ms ease-in-out',
  color: theme.palette.grey[800],
  whiteSpace: 'nowrap',
  '&:hover': { color: `${theme.palette.primary.main} !important` },
  [theme.breakpoints.down(1000)]: {
    display: 'none',
  },
}));
