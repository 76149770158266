import { Swiper } from 'swiper/react';

import { styled } from '@mui/material';

export const StyleSwipper = styled(Swiper)(({theme}) => ({
  width:'50rem',
  [theme.breakpoints.down(1200)]: {
    width:'45rem',
  },
  [theme.breakpoints.down(1100)]: {
    width:'35rem'
  },
  [theme.breakpoints.down(900)]: {
    width:'30rem'
  },
  [theme.breakpoints.down(800)]: {
    width:'20rem'
  },
  [theme.breakpoints.down(700)]: {
    width:'15rem'
  },
  '.swiper-button-next::after': {
    display: 'none',
  },
  '.swiper-button-prev::after': {
    display: 'none',
  },
}));
